<template>
  <div class="page-box page-privacy-clause">
    <div class="bxb-p50 bg-white bxb-con bxb-m50 privacy-con">
      <div class="title text-center">
        <h6 class="">远兰小贷风险隐私政策</h6>
      </div>

      <div class="content bxb-m30-top">
        <p>（本协议于2021年02月09日最新修订）</p>
        <p>
          本应用尊重并保护您的隐私。为了给您提供更准确、更有个性化的服务，您在使用我们的应用时，我们可能会收集和使用您的信息。我们希望通过《远兰小贷风险系统隐私政策》(以下简称“本隐私政策”)帮助您了解我们会收集哪些信息、为什么收集这些信息，会利用这些信息做些什么及如何保护这些信息。了解这些内容对于您行使隐私权及保护您的个人信息至关重要。
          请您在使用我们服务前仔细阅读此《远兰小贷风险系统隐私政策》并充分理解本隐私政策的全部内容，确定了解我们对您个人信息的处理规则。阅读过程中，如您有任何疑问，可联系我们进行咨询;一旦您继续访问或使用远兰小贷风控系统，即表示您同意此等隐私政策，授权并接受我们按照本隐私政策的规定收集、保存、使用、共享、披露您的信息。如您不同意协议中的任何条款，您应立即停止访问远兰小贷风险系统及使用相关服务。
        </p>

        <p>
          本隐私政策适用于您通过任何方式(包括但不限于网站、APP、微信公众号、微信小程序等)对远兰小贷风险系统各项服务的访问和使用。另外，若您通过使用第三方产品和/或服务(如第三方账号)来使用远兰小贷风控系统服务，您的信息还应当适用该第三方的隐私政策。
        </p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          一、个人信息的收集及用途
        </h6>
        <p>
          根据国家法律法规的规定，网络运营者为用户提供信息发布、即时通讯等服务，在与用户签订协议或者确认提供服务时，应当要求用户提供真实身份信息。用户不提供真实身份信息的，网络运营者不得为其提供相关服务。为了符合法律法规要求并更好地为您提供服务，我们将收集并使用您的个人信息。我们承诺将坚持“最小必要化”原则收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。
        </p>
        <p class="text-bold">（ 一 ）个人信息的定义</p>
        <p>
          个人信息，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息，在本隐私政策中涉及的个人信息包括但不限于:
        </p>
        <p>基本信息:包括个人姓名、年龄(生日)、性别、个人电话号码、行业等;</p>
        <p>个人身份信息:个人证件、面部特征等;</p>
        <p>账号信息:包括系统账号、IP地址、邮箱地址及与前述有关的密码等;</p>
        <p>
          个人上网记录:包括网站及APP登录记录、浏览记录、使用记录、点击记录、操作记录等;
        </p>
        <p>
          个人常用设备信息:包括硬件型号、设备 MAC
          地址、操作系统类型、设备识别码等;
        </p>
        <p>个人位置信息:包括定位信息等。</p>
        <p>个人财产信息:包括交易和支付记录等。</p>

        <p class="text-bold">
          （ 二 ）为使用服务用户须提供并授权平台收集、使用的信息
        </p>

        <p>
          在您使用平台服务以及具体功能的过程中，我们可能会收集、保存和使用下列与您有关的信息才能提供上述服务。如果您不提供相关信息，您将无法使用平台的服务/具体功能。请您注意，您在使用平台服务/功能时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。这些服务/功能包括但不限于:
        </p>

        <p>1. 用户注册</p>
        <p>
          当您注册时，您需要至少向我们提供您本人的手机号码用于注册账号，我们将通过发送短信验证码的方式来验证您的身份是否有效。您可以自主设置您的账户密码，并补充个人基本信息包括用户名、个人姓名、性别、身份证号以及相关信息等。这些信息均属于您的“账户信息”，您补充的账户信息将有助于我们为您提供更好的体验。
        </p>
        <p>2. 签到/上传资料</p>
        <p>
          在您进行签到、下户时，我们将收集您的个人位置信息。通过收集这些信息，提交给上级进行审核。
        </p>
        <p>3. 客服功能</p>
        <p>
          您在使用服务的过程中，可能会向客服渠道提出任何问题、意见、建议、投诉、举报等需求。为此我们需收集、使用上述个人信息中的(部分/全部)相关内
          容。有关向第三方披露、共享您的个人信息的情况，将遵循下文中的具体约定。
        </p>
        <p>4. 安全校验功能</p>
        <p>
          在您使用平台服务时，我们需获取您的设备信息（包括mac地址等），当您的账号在陌生设备登录时进行安全校验，以便及时向您提醒可能存在的安全隐患。
        </p>

        <p class="text-bold">（ 三 ）改进产品与服务所必须的功能:</p>

        <p>
          1.
          产品功能改进:为了监测广告投放情况、改进产品BUG、优化产品的稳定性及功能性等目的，我们提供部分功能和服务时需要使用第三方SDK技术。这些第三方SDK可能会收集或使用您的部分个人信息/设备权限（包括但不限于下列列表），我们会以弹窗等提示等明示方式授权，只有在您授权同意后才会获取相关信息。如第三方SDK的数量、收集或使用的个人信息设备权限范围有所变更的，我们将会通过弹窗或其他形式取得您的授权。
        </p>
        <p>
          （1）“高德地图amap”，
          由“高德”提供，用于“为用户提供地图找工作、附近职位等功能”，需要收集或使用的个人信息/设备权限包括：设备信息（IMEI号）、存储权限、网络状态、位置权限。
        </p>
        <p>
          （2）“微信登录”， 由“微信”提供，用于“用户第三方快速登录
          ”，需要收集或使用微信ID。
        </p>

        <p class="text-bold">
          ( 四 ) 您可选择是否授权我们收集和使用您的个人信息的情形
        </p>

        <p>
          1.
          为使您使用平台服务更加高效便捷，从而提升您在平台的服务使用体验，我们可能会通过您的设备收集和使用您的个人信息。如果您不授权这些具体应用权限，您依然可以使用平台基本功能，但您可能无法充分使用这部分功能，包括但不限于:
        </p>

        <p>
          (1)基于位置信息的下户提交资料功能:当您下户提交资料或签到时我们会收集您的位置信息(我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹)来判断您所处的地点。
        </p>

        <p>
          (2)基于摄像头(相机)的上传功能:您可以使用这个功能完成视频拍摄、拍照等功能，帮助您更好的提交资料。
        </p>

        <p>(3)基于图片上传的附加功能:您可以在平台上传您的资料。</p>

        <p>
          (4)基于设备外部存储文件访问、读写、存储的附加功能:您可以调用您设备外部存储中的文件(比如文档文件、照片等)用于资料上传等;也可以相应下载、保存各类文件至外部存储中。
        </p>

        <p>
          2、上述附加功能需要您在您的设备中向我们开启您的地理位置(位置信息)、相机(摄像头)、相册(图片库)、麦克风以及外部存储的访问权限，。您可以在您的终端设备中逐项查看您上述权限的开启状态，并可以随时开启或关闭这些权限。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。
        </p>

        <p class="text-bold">（ 五 ）无需征得授权的情形</p>
        <p>
          根据国家法律法规的相关规定及特定事项的需求，平台在以下情况无需经过您的授权即可收集并使用您的个人信息，您知晓并充分理解此等情形:
        </p>
        <p>与国家安全、国防安全有关的;</p>
        <p>与公共安全、公共卫生、重大公共利益有关的;</p>
        <p>与犯罪侦查、起诉、审判和判决执行等有关的;</p>
        <p>出于维护个人信息主体或其他个人的生命、财产等重大合法权益的;</p>
        <p>所收集的个人信息是个人信息主体自行向社会公众公开的;</p>
        <p>
          从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道;
        </p>
        <p>根据您的要求签订合同所必需的;</p>
        <p>
          用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障;
        </p>
        <p>为合法的新闻报道所必需的;</p>
        <p>
          统计机构、学术研究机构、政府公共部门等基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的;
        </p>
        <p>法律法规规定的其他情形。</p>

        <p class="text-bold">（ 六 ) 从第三方获取您的个人信息</p>
        <p>
          我们可能从第三方获取您授权共享的账户信息(头像、昵称)，并在您同意本隐私政策后将您的第三方账户与您的远兰小贷风险系统账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人
          信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
        </p>

        <p class="text-bold">（ 七 ) 个人信息去标识化后的使用</p>
        <p>
          在收集您的个人信息后，我们可以通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。您了解并同意，在此情况下我们有权使用已经去标识化的信息;并对您的个人信息进行脱敏处理的前提下，我们有权对用户数据库进行分析并予以商业化的利用。比如，我们会对我们服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示互联网行业及招聘领域发展趋势等，但这些统计信息不包含您的任何身份识别信息。
        </p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          二、 个人信息的共享、披露及转让
        </h6>

        <p class="text-bold">(一) 共享</p>
        <p>
          1. 我们不会与任何公司、组织和个人共享您的个人信息，但以下情况除外:
        </p>

        <p>(1)事先获得您明确的同意或授权;</p>

        <p>(2)与国家安全、国防安全、公共安全、公共卫生、重大公共利益有关的;</p>

        <p>(3)与犯罪侦查、起诉、审判和判决执行等有关的;</p>

        <p>
          (4)根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供;
        </p>

        <p>
          (5)在法律法规允许的范围内，为维护用户或社会公众利益、财产或安全免遭损害而有必要提供;
        </p>

        <p>
          (6)只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务;
        </p>

        <p>
          (7)产品与/或服务的提供包含、依赖于第三方的服务(例如基于第三方 SDK
          实现的部分功能)。
        </p>

        <p>(8)为处理您与他人的纠纷或争议;</p>

        <p>
          (9)符合与您签署的相关协议(包括在线签署的电子协议以及相应的平台规则)或其他的法律文件约定所提供;
        </p>

        <p>(10)基于学术研究而使用;</p>

        <p>(11)基于符合法律法规的社会公共利益而使用。</p>

        <p>
          2.
          基于上述情况与关联方、第三方共享您的个人信息时，我们只会共享必要的个人信息。我们的关联方、第三方如果改变个人信息的使用目的应再次征求您的授权同意。
        </p>
        <p></p>

        <p class="text-bold">(二)转让</p>
        <p>
          除以下情形外，我们不会将您的个人信息转让给除远兰小贷风险系统及其关联公司外的第三方:
        </p>

        <p>事先获得您的明确授权或同意;</p>
        <p>满足法律法规、法律程序的要求或强制性的政府要求或司法裁定;</p>
        <p>
          如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并尽量确保在转移后继续受到其他隐私权政策的约束。
        </p>
        <p class="text-bold">(三)披露</p>
        <p>
          在以下情况，且相应采取了达到行业普遍保护措施的情况下，我们才会披露您的个人信息:
        </p>

        <p>您明确授权或同意。</p>
        <p>与国家安全、国防安全、公共安全、公共卫生、重大公共利益有关的;</p>
        <p>与犯罪侦查、起诉、审判和判决执行等有关的;</p>
        <p>
          根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供;
        </p>
        <p>
          在法律法规允许的范围内，为维护用户或社会公众利益、财产或安全免遭损害而有必要提供;
        </p>
        <p>为处理您与他人的纠纷或争议;</p>
        <p>
          符合与您签署的相关协议(包括在线签署的电子协议以及相应的平台规则)或其他的法律文件约定所提供。
        </p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          三、个人信息的保护
        </h6>

        <p>
          我们非常重视个人信息的保护，并尽可能的采取各种措施。在信息安全管理方面，达到经营本网站业务所相适用的水平。
        </p>
        <p></p>

        <p class="text-bold">(一) 从技术手段上</p>
        <p>
          我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受
          SSL
          协议加密保护;我们会使用加密技术提高个人信息的安全性;我们会使用受信赖的保护机制防止个人信息遭到恶意攻击;我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息;以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
        </p>
        <p></p>

        <p>
          我们有行业先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。目前，我们的重要信息系统已经通过网络安全等级保护的三级测评。
        </p>
        <p></p>

        <p>
          但由于技术的限制以及可能存在的各种恶意手段，平台不承诺您的信息的绝对安全。如出现第三方采取不正当手段获取用户个人信息的，在平台已按照法律法规及本隐私政策履行网络运营者应尽的义务情况下，本平台不承担额外的责任。
        </p>
        <p></p>

        <p class="text-bold">(二) 从规则制度上</p>
        <p></p>
        <p>1. 内部制度</p>
        <p></p>

        <p>
          远兰小贷设置了专门部门及岗位负责信息安全保障工作(岗位和人员可能会随公司发展而变动)，利用技术手段实时监测平台运营状况，并合理分配设置不同工作人员后台系统权限;与此同时我们还与每名员工签订《保密协议》，以确保内部有着完善的信息安全管理制度。另外，我们也在不断完善信息安全相关制度规则，以及加强对员工的信息安全教育工作等。
        </p>
        <p></p>

        <p>2. 对于平台其他用户或其他第三方</p>
        <p></p>

        <p>
          如涉及向平台其他用户或其他第三方共享、转让、披露用户个人信息的情形，我们同样会要求他们遵守相关规则，包括平台用户协议、本隐私政策、签署的相
          关协议中的保密条款等。
        </p>
        <p></p>

        <p class="text-bold">(三) 安全事件</p>
        <p></p>
        <p>
          为了应对可能的网络攻击、安全漏洞、信息泄露等风险事件，平台内部制定了相应的安全事件应急预案。一旦发生相关事件，我们将迅速处理并按照法律法规要求上报政府相关部门;同时，我们也将按照法律法规的要求及时向您通知:
          安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。此等通知将以邮件、信函、电话、推送通知等方式呈现，如果难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
        </p>
        <p></p>
        <p>
          如果您发现了任何有关平台的潜在安全风险(如系统漏洞等)或者个人信息安全事件，您可以向平台及时反馈并联系我们。
        </p>
        <p></p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          四、用户对个人信息的管理
        </h6>

        <p class="text-bold">(一) 编辑</p>
        <p></p>

        <p>您可以通过APP“我的”页面，点击个人头像或者个人信息菜单</p>
        <p></p>

        <p class="text-bold">(二)更改或撤销授权</p>
        <p></p>
        <p>
          您可以通过设备功能来取消或撤回部分个人信息的授权，例如:通过手机系统管理
          APP 所能获取的信息等。
        </p>
        <p></p>
        <p>
          您知悉并理解，更改或撤销授权将导致你无法使用平台的部分服务或具体功能。
        </p>
        <p></p>

        <p class="text-bold">(三)注销账号</p>
        <p></p>

        <p>
          如您不需要使用平台的服务，您可以向平台申请注销您的账号。您可以在APP端点击底栏“我——个人信息——注销账号”来提交账号注销申请。请注意，账号注销是不可恢复的操作。
        </p>
        <p></p>

        <p>为保证您的账号安全，账号注销前会审核以下条件：</p>
        <p></p>

        <p>
          1. 账号资产已结清，没有未完成/或存在争议的服务：
          账号中没有资产、欠款、未结清的资金和虚拟权益；本账号及通过本账号接入的第三方中没有未完成和/或存在争议的服务。
        </p>
        <p></p>

        <p>
          2. 账号权限解除：账号已解除与其他产品、网站授权登录和/或绑定关系。
        </p>
        <p></p>

        <p>3. 账号无任何纠纷，包括投诉举报或被投诉举报。</p>
        <p></p>

        <p>
          当您的账号注销申请审核通过后，您的账号将注销。如申请未通过，将通知您未通过的原因，请您进行相应修改或操作。
        </p>
        <p></p>

        <p>
          当您注销账号后，您将无法再通过此账号使用平台的服务，我们将停止收集及使用您的信息。但根据法律法规的相关要求，我们仍需继续保留用户在本网站的相关资料和信息（包括但不限于用户的账号信息、交易信息等）。
        </p>
        <p></p>

        <p>
          以上具体操作流程可能会随着本网站升级而发生调整，我们将通过适当形式来通知您并对操作进行引导。
        </p>
        <p></p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          五、关于未成年人个人信息的处理
        </h6>

        <p>
          如果您是以自己劳动收入为主要生活来源的十六周岁以上的未成年人，则您有完全民事行为能力，您与平台之前的关系完全适用本隐私政策。
        </p>
        <p></p>

        <p>
          如果您不是以自己的劳动收入为主要来源的十六周岁以上的未成年人，您仍然需要在监护人同意及监督下适用我们的产品。平台会在受到法律允许、监护人明确同意或者保护未成年人所必要的情况下收集、使用或公开披露您的个人信息。
        </p>
        <p></p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          六、隐私政策的更新
        </h6>

        <p>
          随着平台经营的发展，本隐私政策将不时更新。我们会通过在网站公告、APP端推送、电子邮件等其他适当方式提醒您相关内容的更新。你也可以随时访问我们的隐私政策页面来获知最新版本。
        </p>
        <p></p>

        <p>
          对于重大变更(我们收集和使用您个人信息方式的变化将导致您的权利实质性的减少或义务的实质性增多)我们会采用更为显著的通知方式。
        </p>
        <p></p>

        <p>
          在您知晓或应当知晓上述更新后，您仍然选择继续是使用我们的服务，视为您对更新的接受。
        </p>
        <p></p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          七、联系我们
        </h6>

        <p>
          关于本隐私政策及个人信息的任何问题，您可以通过产品的在线反馈功能或如下方式同我们联系:
        </p>
        <p></p>

        <p>
          电话:086-0776-2624266 (工作日 9:00-18:00) 邮箱:
          yuanlanxiaodai@outlook.com
          ，通讯地址:广西壮族自治区百色市右江区城东大道136号1楼1号
        </p>
        <p></p>

        <p>
          为了核查您的问题并及时向您反馈，我们可能需要您提交身份证明、有效联系方式和书面请求及相关证据。我们将尽快处理您的问题，您仍然可以按照法律法规规定向有关政府部门进行反馈。
        </p>
        <p></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyClause",
  components: {},
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.page-privacy-clause {
  .privacy-con {
    padding: 50px 50px;
    .title {
      h6 {
        font-size: 36px;
        text-align: center;
      }
    }
    .content {
      line-height: 2.6;
    }
  }
}
.text-main {
  color: #597ef7;
}
.text-bold {
  font-weight: bold;
}
.margin-top-15 {
  margin-top: 15px;
}
.text-size-16 {
  font-size: 18px;
}
</style>
